<template>
    <div style="position:relative;" class="custom-feature-container">
        <div class="container landing-banner-container" style="padding-top: 7.5rem">
            <div class="left-banner">
                <div>
                    <h1 style="font-size: 3.625rem;font-weight: bold;line-height: 1.21;color: #021222;padding-bottom: 1.875rem;">Proof of Work Completion: Ensuring Accountability and Efficiency in Completing Projects</h1>
                    <p>Proof of work completion plays a critical role in various industries; for instance, construction, plumbing, <a href= "https://recordtime.com.au/electrical-contractor-software">electrical contracting</a>, heavy civil projects, cleaning, and delivery businesses. It serves as evidence that the assigned tasks or projects have been successfully completed and meet the required standards. Moreover, this digital evidence holds significant importance for project managers, clients, and stakeholders as it enables them to track progress. However, managing and validating proof of work completion can be challenging without efficient systems in place. 
                        <br/><br/>With Record TIME, users can document and share evidence of their completed tasks. This feature not only streamlines the process of validating work but also enhances collaboration between project teams by providing real-time updates and progress tracking. The platform's transparency and collaboration features ensure that all stakeholders have access to a comprehensive and immutable record of documents. It enhances trust and communication, making Record TIME a valuable tool for successful project outcomes.  Record TIME simplifies work validation, leading to a more transparent and error-free workflow, ultimately saving time and resources for all parties involved with proof of work completion.
                    </p>
                    <v-btn color="secondary" height="55" :width="$vuetify.breakpoint.xsOnly ? '' : '236'"
                @click="redirectToLink('https://recordtime.com.au/cleaning-business-software-australia')" class="mr-7 btn-1" elevation="0">
                Read More
            </v-btn>
            <v-btn height="56" class="get-demo-btn"  color="white" @click="getDemo()">
                Get A Demo Now
            </v-btn>
                </div>
            </div>
            <div  class="right-banner">
                <img :src="`${publicPath}assets/features-image/work-report.webp`" alt="proof of work template" style="max-width: 100%;">
            </div>
            <div style="clear: both;"></div>
        </div>
        <br/><br/>
        <v-container><br/>
            <h2>Streamlining Work Validation with Visual Proof Submission</h2><br/>
            <p>Record TIME offers an innovative and user-friendly approach to validate work and streamline the proof of work completion process. With the advent of smartphones and their ubiquitous presence, capturing images has become second nature to most people. Recognising this, Record Time has harnessed the power of pictures to provide a straightforward method for workers to submit visual proof with digital forms and dockets. By leveraging their device's camera, employees can snap a photo of their completed task or package drop-off.  Consequently, this eliminates the need for complex paperwork or lengthy descriptions. In addition, this simple and intuitive process not only saves time but also minimises the chances of errors or miscommunication.</p>
            <br/>
            <p>Because of the different perspectives of individuals, it becomes challenging to be sure if a task is done right, leading to potential errors and subsequent frustration for all parties involved. Therefore, validating work completion is an important aspect of the business workflow.   
            Record TIME adds a layer of transparency to this process. Through its user-friendly and multitasking dashboard structure, docket data becomes visible to numerous permission users. Our software ensures a comprehensive and immutable record of all documents shared within the system.
            </p>
          <div class="hidden-sm-and-down"><br/><br/></div>
            <img :src="`${publicPath}assets/features-image/send-proof-of-work.webp`" alt="send proof of work completion with record time" style="max-height: 800px; height: 100%;object-fit: contain;width: 100%;transform: none;"><br/><br/>
            <div class="gray-container"><br/><br/>
                <h3>Benefits of Using Record TIME to Submit Proof of Work Completion</h3>
                <img :src="`${publicPath}assets/features-image/record-time-for-proof-of-work-submission.webp`" alt="Benefits of using Record TIME for Proof of Work Submission " style="margin-top: 20px; object-fit: contain;width: 100%;">
             </div><br/><br/>
            <h2>Add E-Signatures in Dockets and Forms</h2><br/>
            <p>Record Time provides integrated e-signature functionality to enhance the credibility of work completion. Additionally, with the ability for workers and clients to electronically sign documents, invoices, or delivery receipts, businesses can eliminate the requirement for physical signatures. As a result, they can enjoy smooth and time-saving cooperation. The e-signatures offered by Record Time are legally binding, adding an additional layer of security and authenticity to the proof documents.</p>
        </v-container>
        <get-demo-modal v-if="getDemoModal" :demoEmail="demoEmail" @excEmit="excEmit" @getDemoModalClose="getDemoModalClose" @demoResponse="demoResponse" url="https://www.youtube.com/embed/62kV7CIU_F4"/>

    </div>
</template>
<style lang="scss">
    .landing-banner-container{
        @media only screen and (max-width: 600px) {
            padding-top: 20px !important;
        }
    }
    .left-banner{
        width: 60%; 
        float: left;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }
    .right-banner{
        width: 40%; 
        float: right;
        @media only screen and (max-width: 1280px) {
            width: 100%;
        }
    }

    .custom-feature-container{
        .banner-img{
            @media only screen and (max-width: 1280px) {
                width: 100% !important;
                top: 20px !important;
                height: auto !important;
            }
        }
        p{
            @media only screen and (max-width: 1280px) {
                padding-bottom: 0px !important;
            }
        }
    }
    .customH2{
        position: relative;
        display: inline-block;
        padding-right: 55px;
        margin-bottom: 15px;
        color: #00C8D8;
        img{
            position: absolute;
            right: 0px;
        }
    }
    .gray-container{
        background: #F8F9FB;
        padding: 20px;
        margin-top: 30px;
        h3 {
            font-size: 32px;
            font-weight: bold;
            text-align: center;
            color: $black-pearl;
            margin-bottom: 15px;

            span {
            color: $faux-dark-turquoise;
            }
        }
        p{
            margin-top: 40px;
            text-align: center;
            color: #000;
        }
        .list{
            h1{
                color: #00C8D8;
            }
            p{
                color: #000;
                text-align: left;
            }
        }
    }
    .left-blog1{
      float:left;
      max-width: calc(100% - 500px);
      padding-right: 15px;
    }
    .right-blog1{
      width: 500px;
      float:right;
      img{
        max-width: 500px;
        width: 100%;
      }
    }
    @media only screen and (max-width: 600px) {
      .left-blog1{
        float: none;
        max-width: 100%;
        padding-right: 0px;
      }
      .right-blog1{
        float: none;
        max-width: 100%;
      }
    }
</style>
<script>
import GetDemoModal from '@/components/GetDemoModal.vue';

export default{
    metaInfo: {
        title: 'Proof of Work Completion: Accountability and Efficiency with Proof',
        // titleTemplate: 'Record TIME → %s',
        meta: [
        {name: 'name', content: ''},
        {name: 'description', content: 'Record TIME Proof of Work Completion with visual proof submission and e-signatures ensures accountability and efficiency in project management'},
        {name: 'keyword', content: 'proof of work completion'}
        ],
    link: [
      { rel: 'canonical', href: 'https://recordtime.com.au/proof-of-work-completion' }
    
    ]
  },
    mounted() {
        var noscript = document.getElementsByTagName('noscript');
        noscript[0].remove()
    },
    components: {
        GetDemoModal
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            getDemoModal: false,
        }
    },
    methods: {
        redirectToLink(url) {
        window.open(url, '_blank');
        },
        getDemoModalClose(){
            this.getDemoModal = false;
            this.demoEmail = '';
        },
        getDemo(){
            this.getDemoModal = true;
        }
    }
}
</script>
